import React, { useState, useEffect, useRef } from 'react';
import './ProgressSection.css';

const steps = [
    { id: 1, title: 'Order', description: (<>
            The first step is for you to place an order.
            <br />
            Once received, we’ll reach out to discuss your expectations and the scale of your smart home project.
        </>) },
    { id: 2, title: 'Modeling', description: 'We design your smart home system and order the necessary parts. This involves creating a house plan based on your order and selecting the devices required.' },
    { id: 3, title: 'Installation', description: 'Once the plan is finalized and all devices are delivered, we’ll proceed with the installation and setup in your home, ensuring everything matches your specifications.' },
    { id: 4, title: 'Adjusting', description: 'After installation, you’ll start using your smart home. During this time, if any adjustments are needed, simply contact us, and we’ll make the necessary changes.' },
    { id: 5, title: 'Support*', description: (
            <>
                Support is an optional premium service. By subscribing, you’ll receive ongoing support if anything breaks or needs fixing after the adjustment period, we’ll handle it for free.
                Without a subscription, services will be available at a standard service call rate.
                <br />
            </>
        ), note: (
            <>
                <strong>Note:</strong> We offer a one-year guarantee to fix any issues with our installations at no additional cost. The premium service is available for other situations beyond this coverage.
            </>
        ) },
];

const ProgressSection = () => {
    const [activeStep, setActiveStep] = useState(1);
    const [fade, setFade] = useState(false);
    const contentRef = useRef(null);

    useEffect(() => {
        if (contentRef.current) {
            contentRef.current.style.maxHeight = `${contentRef.current.scrollHeight}px`;
        }
    }, [activeStep]);

    const handleStepClick = (stepId) => {
        setFade(true);
        setTimeout(() => {
            setActiveStep(stepId);
            setFade(false);
        }, 300);
    };

    return (
        <div className="progress-section">
            <div className="progress-bar">
                <div
                    className="progress-bar-line"
                    style={{
                        width: `${(activeStep - 1) * (100 / (steps.length - 1))}%`,
                    }}
                ></div>
                {steps.map((step) => (
                    <div
                        key={step.id}
                        className={`progress-step ${activeStep === step.id ? 'active' : ''}`}
                        onClick={() => handleStepClick(step.id)}
                    >
                        <div className="circle">{step.id}</div>
                        <span className="circle-title">{step.title}</span>
                    </div>
                ))}
            </div>
            <div
                className={`step-info ${fade ? 'fade-out' : 'fade-in'}`}
                ref={contentRef}
            >
                <h3>{steps[activeStep - 1].title}</h3>
                <p>{steps[activeStep - 1].description}</p>
                <i>{steps[activeStep - 1].note}</i>
            </div>
        </div>
    );
};

export default ProgressSection;
