import React, { useState } from 'react';
import './LicensePage.css';
import Header from "../pageComponents/headerFooter/Header";
import BackHomeBtn from "../pageComponents/backHomeBtn/backHomeBtn";
import Footer from "../pageComponents/headerFooter/Footer";
import { mdiPlus, mdiMinus } from '@mdi/js'; // Import MDI icons
import Icon from '@mdi/react'; // Import the Icon component

const LicensePage = () => {
    const [openIndex, setOpenIndex] = useState(null);

    const faqs = [
        {
            question: 'SMART BIRD SOLUTION CUSTOMIZED HOME ASSISTANT LICENSE AGREEMENT',
            answer: (
                <>
                    <h2>License Agreement</h2>
                    <p>
                        This License Agreement outlines the terms and conditions governing the use of the customized version of the Home Assistant software provided by Smart Bird Solution ("Licensor"). By using, installing, or redistributing this customized version, you ("Licensee") agree to the following terms:
                    </p>

                    <h3>SECTION 1 - GRANT OF LICENSE</h3>
                    <p>
                        <strong>Home Assistant Core:</strong> The core of this software is released under the Apache 2.0 License, and as such, all use, reproduction, and distribution of the core Home Assistant software must comply with the terms and conditions of the Apache 2.0 License, as outlined below in Appendix A.
                    </p>
                    <p>
                        <strong>Customization:</strong> The customizations and improvements made by Smart Bird Solution are proprietary and licensed to the customer under the following conditions. You are granted a non-exclusive, non-transferable, revocable license to use the customizations in conjunction with your Home Assistant setup as long as you remain a customer of Smart Bird Solution.
                    </p>

                    <h3>SECTION 2 - RESTRICTIONS ON USE AND DISTRIBUTION OF CUSTOMIZATIONS</h3>
                    <p>
                        <strong>Non-Disclosure and Redistribution:</strong> Licensee is prohibited from sharing, distributing, or contributing any customizations made by Smart Bird Solution to any third parties or open-source projects, including but not limited to any community contributions to the Home Assistant project.
                    </p>
                    <p>
                        <strong>Modification and Reverse Engineering:</strong> Licensee shall not modify, reverse-engineer, or attempt to extract the custom code provided by Smart Bird Solution.
                    </p>

                    <h3>SECTION 3 - TERMINATION AND CONTINUED USE</h3>
                    <p>
                        <strong>Termination of Service:</strong> If Licensee decides to stop working with Smart Bird Solution or if the License Agreement is terminated, Licensee must remove all customizations and proprietary configurations implemented by Smart Bird Solution. This includes flashing your Home Assistant installation to remove any customizations provided by Smart Bird Solution.
                    </p>
                    <p>
                        <strong>Transfer of Ownership:</strong> If Licensee sells or transfers ownership of the system or device where the customized Home Assistant software is installed, the Licensee must ensure that all customizations provided by Smart Bird Solution are removed prior to the sale or transfer. Licensee may revert the system to the original Home Assistant build but must not transfer any part of the custom code.
                    </p>

                    <h3>SECTION 4 - ACCEPTING WARRANTY OR ADDITIONAL LIABILITY</h3>
                    <p>
                        <strong>Warranties:</strong> The software is provided "AS IS," without any warranties or conditions of any kind. Smart Bird Solution disclaims all warranties, including but not limited to warranties of merchantability, fitness for a particular purpose, and non-infringement.
                    </p>
                    <p>
                        <strong>Limitation of Liability:</strong> In no event shall Smart Bird Solution be liable for any damages, including but not limited to direct, indirect, incidental, or consequential damages, resulting from the use of this software or customizations.
                    </p>
                </>
            )
        },
        {
            question: 'APPENDIX A - APACHE 2.0 LICENSE FOR HOME ASSISTANT CORE',
            answer: (
                <>
                    <h2><strong>License Agreement</strong></h2>
                    <p>
                        This section applies to the core Home Assistant software, which is licensed under the <strong>Apache License 2.0</strong>:
                    </p>
                    <p>
                        <strong>Apache License</strong><br />
                        Version 2.0, January 2004<br />
                        <a href="http://www.apache.org/licenses/" target="_blank" rel="noopener noreferrer">
                            http://www.apache.org/licenses/
                        </a>
                    </p>

                    <p>
                        <strong>TERMS AND CONDITIONS FOR USE, REPRODUCTION, AND DISTRIBUTION</strong>
                    </p>

                    <p>
                        <strong>1. Definitions.</strong><br />
                        <strong>"License"</strong> shall mean the terms and conditions for use, reproduction, and distribution as defined by Sections 1 through 9 of this document.<br />
                        <strong>"Licensor"</strong> shall mean the copyright owner or entity authorized by the copyright owner that is granting the License.<br />
                        <strong>"Legal Entity"</strong> shall mean the union of the acting entity and all other entities that control, are controlled by, or are under common control with that entity. For the purposes of this definition, "control" means (i) the power, direct or indirect, to cause the direction or management of such entity, whether by contract or otherwise, or (ii) ownership of fifty percent (50%) or more of the outstanding shares, or (iii) beneficial ownership of such entity.<br />
                        <strong>"You"</strong> (or <strong>"Your"</strong>) shall mean an individual or Legal Entity exercising permissions granted by this License.<br />
                        <strong>"Source"</strong> form shall mean the preferred form for making modifications, including but not limited to software source code, documentation source, and configuration files.<br />
                        <strong>"Object"</strong> form shall mean any form resulting from mechanical transformation or translation of a Source form, including but not limited to compiled object code, generated documentation, and conversions to other media types.<br />
                        <strong>"Work"</strong> shall mean the work of authorship, whether in Source or Object form, made available under the License, as indicated by a copyright notice that is included in or attached to the work (an example is provided in the Appendix below).<br />
                        <strong>"Derivative Works"</strong> shall mean any work, whether in Source or Object form, that is based on (or derived from) the Work and for which the editorial revisions, annotations, elaborations, or other modifications represent, as a whole, an original work of authorship. For the purposes of this License, Derivative Works shall not include works that remain separable from, or merely link (or bind by name) to the interfaces of, the Work and Derivative Works thereof.<br />
                        <strong>"Contribution"</strong> shall mean any work of authorship, including the original version of the Work and any modifications or additions to that Work or Derivative Works thereof, that is intentionally submitted to Licensor for inclusion in the Work by the copyright owner or by an individual or Legal Entity authorized to submit on behalf of the copyright owner. For the purposes of this definition, "submitted" means any form of electronic, verbal, or written communication sent to the Licensor or its representatives, including but not limited to communication on electronic mailing lists, source code control systems, and issue tracking systems that are managed by, or on behalf of, the Licensor for the purpose of discussing and improving the Work, but excluding communication that is conspicuously marked or otherwise designated in writing by the copyright owner as "Not a Contribution."<br />
                        <strong>"Contributor"</strong> shall mean Licensor and any individual or Legal Entity on behalf of whom a Contribution has been received by Licensor and subsequently incorporated within the Work.
                    </p>

                    <p>
                        <strong>2. Grant of Copyright License.</strong> Subject to the terms and conditions of this License, each Contributor hereby grants to You a perpetual, worldwide, non-exclusive, no-charge, royalty-free, irrevocable copyright license to reproduce, prepare Derivative Works of, publicly display, publicly perform, sublicense, and distribute the Work and such Derivative Works in Source or Object form.
                    </p>

                    <p>
                        <strong>3. Grant of Patent License.</strong> Subject to the terms and conditions of this License, each Contributor hereby grants to You a perpetual, worldwide, non-exclusive, no-charge, royalty-free, irrevocable (except as stated in this section) patent license to make, have made, use, offer to sell, sell, import, and otherwise transfer the Work, where such license applies only to those patent claims licensable by such Contributor that are necessarily infringed by their Contribution(s) alone or by combination of their Contribution(s) with the Work to which such Contribution(s) was submitted. If You institute patent litigation against any entity (including a cross-claim or counterclaim in a lawsuit) alleging that the Work or a Contribution incorporated within the Work constitutes direct or contributory patent infringement, then any patent licenses granted to You under this License for that Work shall terminate as of the date such litigation is filed.
                    </p>

                    <p>
                        <strong>4. Redistribution.</strong> You may reproduce and distribute copies of the Work or Derivative Works thereof in any medium, with or without modifications, and in Source or Object form, provided that You meet the following conditions:<br />
                        (a) You must give any other recipients of the Work or Derivative Works a copy of this License;<br />
                        (b) You must cause any modified files to carry prominent notices stating that You changed the files;<br />
                        (c) You must retain, in the Source form of any Derivative Works that You distribute, all copyright, patent, trademark, and attribution notices from the Source form of the Work, excluding those notices that do not pertain to any part of the Derivative Works;<br />
                        (d) If the Work includes a "NOTICE" text file as part of its distribution, then any Derivative Works that You distribute must include a readable copy of the attribution notices contained within such NOTICE file, excluding those notices that do not pertain to any part of the Derivative Works, in at least one of the following places: within a NOTICE text file distributed as part of the Derivative Works; within the Source form or documentation, if provided along with the Derivative Works; or, within a display generated by the Derivative Works, if and wherever such third-party notices normally appear. The contents of the NOTICE file are for informational purposes only and do not modify the License. You may add Your own attribution notices within Derivative Works that You distribute, alongside or as an addendum to the NOTICE text from the Work, provided that such additional attribution notices cannot be construed as modifying the License.
                    </p>

                    <p>
                        You may add Your own copyright statement to Your modifications and may provide additional or different license terms and conditions for use, reproduction, or distribution of Your modifications, or for any such Derivative Works as a whole, provided Your use, reproduction, and distribution of the Work otherwise complies with the conditions stated in this License.
                    </p>

                    <p>
                        <strong>5. Submission of Contributions.</strong> Unless You explicitly state otherwise, any Contribution intentionally submitted for inclusion in the Work by You to the Licensor shall be under the terms and conditions of this License, without any additional terms or conditions. Notwithstanding the above, nothing herein shall supersede or modify the terms of any separate license agreement you may have executed with Licensor regarding such Contributions.
                    </p>

                    <p>
                        <strong>6. Trademarks.</strong> This License does not grant permission to use the trade names, trademarks, service marks, or product names of the Licensor, except as required for reasonable and customary use in describing the origin of the Work and reproducing the content of the NOTICE file.
                    </p>

                    <p>
                        <strong>7. Disclaimer of Warranty.</strong> Unless required by applicable law or agreed to in writing, Licensor provides the Work (and each Contributor provides its Contributions) on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied, including, without limitation, any warranties or conditions of TITLE, NON-INFRINGEMENT, MERCHANTABILITY, or FITNESS FOR A PARTICULAR PURPOSE. You are solely responsible for determining the appropriateness of using or redistributing the Work and assume any risks associated with Your exercise of permissions under this License.
                    </p>

                    <p>
                        <strong>8. Limitation of Liability.</strong> In no event and under no legal theory, whether in tort (including negligence), contract, or otherwise, unless required by applicable law (such as deliberate and grossly negligent acts) or agreed to in writing, shall any Contributor be liable to You for damages, including any direct, indirect, special, incidental, or consequential damages of any character arising as a result of this License or out of the use or inability to use the Work (including but not limited to damages for loss of goodwill, work stoppage, computer failure or malfunction, or any and all other commercial damages or losses), even if such Contributor has been advised of the possibility of such damages.
                    </p>

                    <p>
                        <strong>9. Accepting Warranty or Additional Liability.</strong> While redistributing the Work or Derivative Works thereof, You may choose to offer, and charge a fee for, acceptance of support, warranty, indemnity, or other liability obligations and/or rights consistent with this License. However, in accepting such obligations, You may act only on Your own behalf and on Your sole responsibility, not on behalf of any other Contributor, and only if You agree to indemnify, defend, and hold each Contributor harmless for any liability incurred by, or claims asserted against, such Contributor by reason of your accepting any such warranty or additional liability.
                    </p>

                    <p>
                        <strong>END OF TERMS AND CONDITIONS</strong>
                    </p>

                    <p>
                        <strong>NOTICE</strong><br />
                        This license is based on the Apache License, Version 2.0, January 2004. You can obtain a copy of the license at the following link:
                    </p>
                    <p>
                        <a href="http://www.apache.org/licenses/" target="_blank" rel="noopener noreferrer">
                            http://www.apache.org/licenses/
                        </a>
                    </p>
                </>
            )
        },
        {
            question: 'HomeAssistant Licenses',
            answer: (
                <>
                    <p><strong>Contributor License Agreement</strong><br />
                        <a href="https://www.home-assistant.io/developers/cla/" target="_blank" rel="noopener noreferrer">
                            https://www.home-assistant.io/developers/cla/
                        </a>
                    </p>

                    <p><strong>Privacy Policy</strong><br />
                        <a href="https://www.home-assistant.io/privacy/" target="_blank" rel="noopener noreferrer">
                            https://www.home-assistant.io/developers/cla/
                        </a>
                    </p>
                    <p><strong>Terms of Service</strong><br />
                        <a href="https://www.home-assistant.io/tos/" target="_blank" rel="noopener noreferrer">
                            https://www.home-assistant.io/developers/cla/
                        </a>
                    </p>
                    <p><strong>Code of Conduct</strong><br />
                        <a href="https://www.home-assistant.io/code_of_conduct/" target="_blank" rel="noopener noreferrer">
                            https://www.home-assistant.io/developers/cla/
                        </a>
                    </p>
                    <p><strong>Credits</strong><br />
                        <a href="https://www.home-assistant.io/developers/credits/" target="_blank" rel="noopener noreferrer">
                            https://www.home-assistant.io/developers/cla/
                        </a>
                    </p>
                    <p><strong>License</strong><br />
                        <a href="https://www.home-assistant.io/developers/license/" target="_blank" rel="noopener noreferrer">
                            https://www.home-assistant.io/developers/cla/
                        </a>
                    </p>

                </>
            )
        },
        {
            question: 'Other',
            answer: (
                <>
                    <p><strong>Terms of Service</strong><br />
                        <a href="/terms-of-service/" target="_blank" rel="noopener noreferrer">
                            Link to Our Terms of Service
                        </a>
                    </p>
                    <br/>

                    <p><strong>Privacy Policy</strong><br />
                        <a href="/privacy-policy/" target="_blank" rel="noopener noreferrer">
                            Link to Our Privacy Policy
                        </a>
                    </p>

                </>
            )
        },
    ];

    return (
        <div className="faq-page">
            <Header titleName="Smart Bird Solution Licenses"/>
            <section className="faq-section">
                <h3 className="faq-title">All Licenses</h3>
                <div className="faq-wrapper">
                    {faqs.map((faq, index) => (
                        <div key={index} className={`faq-item ${openIndex === index ? 'open' : ''}`}>
                            <div
                                className="faq-summary"
                                onClick={() => setOpenIndex(openIndex === index ? null : index)}
                            >
                                <div className="faq-question">{faq.question}</div>
                                <div className="faq-expand-icon">
                                    <button className="expand-btn">
                                        <Icon
                                            path={openIndex === index ? mdiMinus : mdiPlus} // MDI icons
                                            size={1}
                                            color="#007bff"
                                        />
                                    </button>
                                </div>
                            </div>
                            <div className={`faq-details ${openIndex === index ? 'show' : ''}`}>
                                {faq.answer}
                            </div>
                        </div>
                    ))}
                </div>
            </section>
            <BackHomeBtn/>
            <Footer/>
        </div>
    );
};

export default LicensePage;
