import './OrderBtn.css';


const OrderBtn = () => {
    return (
        <a href="#contact"> <button className="btn">
            Contact us
        </button></a>
    );
};

export default OrderBtn;
