// import React, { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./components/appRoutes/AppRoutes";
import "./App.css";

function App() {
    // const [firstName, setFirstName] = useState("John");

    return (
        <div className="App">
            <BrowserRouter>
                <AppRoutes />
            </BrowserRouter>
        </div>
    );
}

export default App;
