import React from "react";
import "./backHomeBtn.css";


const BackHomeBtn = () => {
    return (
        <a href="/"> <button className="back-button" href="/">
            Back to Home
        </button></a>
    );
};

export default BackHomeBtn;
