import React from "react";
import Icon from '@mdi/react';
import { mdiMapMarker, mdiEmailOutline, mdiCellphoneSound } from '@mdi/js';
import { mdiFacebook, mdiYoutube, mdiInstagram } from '@mdi/js';
import "./FooterSection.css";  // Import Footer CSS

const FooterSection = () => {
    return (
        <div className="homepage">
            <footer>
                <div className="header">
                    <div className="waves-container-footer">
                        <svg className="waves footer-waves" xmlns="http://www.w3.org/2000/svg" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
                            <defs>
                                <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                            </defs>
                            <g className="parallax">
                                <use href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7)" />
                                <use href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                                <use href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                                <use href="#gentle-wave" x="48" y="7" fill="#fff" />
                            </g>
                        </svg>
                    </div>
                    <div className="top_header">
                        <section>
                            <Icon path={mdiMapMarker} size={1.5} color="currentColor" />
                            <span>Chicago, IL</span>
                        </section>
                        <section>
                            <Icon path={mdiCellphoneSound} size={1.5} color="currentColor" />
                            <span>(843) 000 000</span>
                        </section>
                        <section>
                            <Icon path={mdiEmailOutline} size={1.5} color="currentColor" />
                            <span>SmartBirdService@gmail.com</span>
                        </section>
                    </div>
                    <div className="bottom_content">
                        <section className="socials">
                            <a href="#"><Icon path={mdiFacebook} size={1} color="currentColor" /></a>
                            <a href="#"><Icon path={mdiInstagram} size={1} color="currentColor" /></a>
                            <a href="#"><Icon path={mdiYoutube} size={1} color="currentColor" /></a>
                        </section>
                        <section className="links">
                            <a href="/">Home</a>
                            <a href="/services">Services</a>
                            <a href="/privacy-policy">Privacy Policy</a>
                            <a href="/terms-of-service/">Terms Of Service</a>
                            <a href="/license/">License</a>
                            {/*<a href="/faq">FAQ</a>*/}
                            <a href="/#contact">Contact Us</a>
                        </section>
                    </div>
                    <div className="border-shape"></div>
                    <div className="copyright">
                        © 2024 SmartBirdSolution - All rights reserved
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default FooterSection;
