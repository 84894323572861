import React, { useEffect } from 'react';
import "./NotFoundPage.css";

const NotFoundPage = () => {
    useEffect(() => {
        // Apply the specific class to the body when the component mounts
        document.body.classList.add('pageNotFound-background');

        // Clean up the class when the component unmounts
        return () => {
            document.body.classList.remove('pageNotFound-background');
        };
    }, []);

    return (
        <div>
            <p className="pageNotFoundStyle">
                Page not found<br />
                <span className="bigger flickering">404</span>
            </p>
            <p className="infoPageNotFound pageNotFoundStyle">Please go back.</p>
        </div>
    );
};

export default NotFoundPage;