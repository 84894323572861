import React from "react";
import "./HeaderSection.css";  // Import Header CSS

const HeaderSection = ({ titleName }) => {
    return (
        <div className="homepage">
            <header className="header">
                <div className="inner-header flex">
                    <div className="homeHeaderTitle">{titleName}</div>
                </div>
                <div className="waves-container">
                    <svg className="waves" xmlns="http://www.w3.org/2000/svg" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
                        <defs>
                            <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                        </defs>
                        <g className="parallax">
                            <use href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7)" />
                            <use href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                            <use href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                            <use href="#gentle-wave" x="48" y="7" fill="#fff" />
                        </g>
                    </svg>
                </div>
            </header>
        </div>
    );
};

export default HeaderSection;
