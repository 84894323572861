import React from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "../homePage/HomePage"; // Adjust the path according to where your HomePage.jsx is located
import NotFound from "../notFoundPage/NotFoundPage";
import ServicesPage from "../servicesPage/ServicesPage";
import PrivacyPolicy from "../privacyPolicy/PrivacyPolicy";
import TermsOfService from "../termsOfService/TermsOfService";
import FAQPage from "../faqPage/FAQPage";
import LicensePage from "../licensePage/LicensePage";

const AppRoutes = () => {
    return (
        <Routes>
            <Route exact path="/" element={<HomePage />} />
            <Route exact path="/services/" element={<ServicesPage/>} />
            <Route exact path="/privacy-policy/" element={<PrivacyPolicy/>} />
            <Route exact path="/terms-of-service/" element={<TermsOfService/>} />
            <Route exact path="/faq/" element={<FAQPage/>} />
            <Route exact path="/license/" element={<LicensePage/>} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
};

export default AppRoutes;
