import React from 'react';
import './CustomPricing.css';
import houseOne from "../../pageComponents/icons/svg/customOptions/lightImage.svg";
import houseTwo from "../../pageComponents/icons/svg/customOptions/securityImage.svg";
import houseThree from "../../pageComponents/icons/svg/customOptions/emergencyImage.svg";
import houseFour from "../../pageComponents/icons/svg/customOptions/otherImage.svg";

const descriptionLight = <>
    Adjustable brightness for day and night<br/>
    Full light automation on human presence.<br/>
    And more...<br/>
</>;

const descriptionSecurity = <>
    Cameras integration<br/>
    Window, door theft protection<br/>
    Empty home secure<br/>
    Lock door detector<br/>
    Alerts & notifications<br/>
    And more...<br/>
</>;

const descriptionEmergency = <>
    Water leak detection<br/>
    Smoke detection<br/>
    Gas detection<br/>
    Alerts & notifications<br/>
    And more...<br/>
</>;

const descriptionOther = <>
    Car automation<br/>
    Humidity automations<br/>
    Smart vents<br/>
    Thermostat setup<br/>
    Energy usage dashboard<br/>
    NFC tags<br/>
    And more...<br/>
</>;

const cardData = [
    { id: 1, title: 'Light', price: 'Contact for Price', description: descriptionLight, icon: houseOne },
    { id: 2, title: 'Security', price: 'Contact for Price', description: descriptionSecurity, icon: houseTwo },
    { id: 3, title: 'Emergency', price: 'Contact for Price', description: descriptionEmergency, icon: houseThree },
    { id: 4, title: 'Other', price: 'Contact for Price', description: descriptionOther, icon: houseFour },
];

const NewPricingSection = () => {
    return (
        <div className="new-pricing-section">
            {cardData.map(card => (
                <div key={card.id} className="new-card">
                    <div className="new-card__icon-container">
                        <img src={card.icon} alt={card.title} className="new-card__icon" style={{ width: '70px', height: 'auto' }} />
                    </div>
                    <div className="new-card__content">
                        <h3 className="new-card__title">{card.title}</h3>
                        <p className="new-card__price">{card.price}</p>
                        <div className="new-card__description">
                            {card.description}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default NewPricingSection;
