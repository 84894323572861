import React, { useState, useEffect } from 'react';
import './EquipmentSection.css';
import application from "../../pageComponents/img/Application.png";
import functionality from "../../pageComponents/img/Functionality.png";
import integration from "../../pageComponents/img/Integration.png";
import routines from "../../pageComponents/img/Routines.png";
import settingsPhoneImg from "../../pageComponents/img/settingsPhone.png";
import roomControlImg from "../../pageComponents/img/roomControl.png";
import modeControlImg from "../../pageComponents/img/modeControl.png";
import ventControlImg from "../../pageComponents/img/ventControl.png";
import tabletApplicationImg from "../../pageComponents/img/tabletApplication.png";
import tablet2ApplicationImg from "../../pageComponents/img/tablet2Application.png";
const EquipmentSection = () => {
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [sliderIndex, setSliderIndex] = useState(0);

    const equipmentItems = [
        {
            title: 'Integrations',
            description: 'Smart Bird has 2,800+ built in integrations that cover a variety of smart home\n' +
                'devices and services.',
            imgSrc: integration,
            alt: 'Integrations'
        },
        {
            title: 'Routines',
            description: 'Flexible routines accommodate various scenarios, ensuring your smart home\n' +
                'operates exactly as you want.',
            imgSrc: routines,
            alt: 'Routines'
        },
        {
            title: 'Functionality',
            description: 'Enhancing device functionality through programming for superior performance',
            imgSrc: functionality,
            alt: 'Functionality'
        },
        {
            title: 'Connect Application',
            description: 'Easily control your sensors, arm and disarm your system, view your camera feed, and more!',
            imgSrc: application,
            alt: 'Connect Application'
        },
    ];

    const sliderItems = [
        {
            imgSrc: application,
            text: 'Comfortable phone visual interface.'
        },
        {
            imgSrc: tabletApplicationImg,
            text: 'Comfortable tablet visual interface.'
        },
        {
            imgSrc: settingsPhoneImg,
            text: 'Custom parameters available for each customer'
        },
        {
            imgSrc: roomControlImg,
            text: 'Room control'
        },
        {
            imgSrc: modeControlImg,
            text: 'Home Mode settings'
        },
        {
            imgSrc: ventControlImg,
            text: 'Vent control'
        },
        {
            imgSrc: tablet2ApplicationImg,
            text: 'Custom design available'
        }
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setSliderIndex((prevIndex) => (prevIndex + 1) % sliderItems.length);
        }, 5000);

        return () => clearInterval(interval);
    }, [sliderItems.length-1]);

    return (
        <div className="equipment-section">
            <div className="equipment-container">
                <h2 className="title">All in one.</h2>
                <div className="equipment-content">
                    <div className="equipment-list">
                        {equipmentItems.map((item, index) => (
                            <div
                                key={index}
                                className={`equipment-item ${hoveredIndex === index ? 'hovered' : ''}`}
                                onMouseEnter={() => setHoveredIndex(index)}
                                onMouseLeave={() => setHoveredIndex(null)}
                            >
                                <img src={item.imgSrc} alt={item.alt} className="equipment-image" />
                                <div className="equipment-info">
                                    <h4 className="equipment-title">{item.title}</h4>
                                    <p className="equipment-description">{item.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="slider-container">
                        <div
                            className="slider-wrapper"
                            style={{ transform: `translateX(-${sliderIndex * 100}%)` }}
                        >
                            {sliderItems.map((item, index) => (
                                <div key={index} className="slider-item">
                                    <img src={item.imgSrc} alt={item.text} className="slider-image" style={{ width: '300px', height: 'auto' }} />
                                    <div className="slider-text">{item.text}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EquipmentSection;
