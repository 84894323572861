import React, { useState } from 'react';
import './FAQPage.css';
import Header from "../pageComponents/headerFooter/Header";
import BackHomeBtn from "../pageComponents/backHomeBtn/backHomeBtn";
import Footer from "../pageComponents/headerFooter/Footer";
import { mdiPlus, mdiMinus } from '@mdi/js'; // Import MDI icons
import Icon from '@mdi/react'; // Import the Icon component

const FAQPage = () => {
    const [openIndex, setOpenIndex] = useState(null);

    const faqs = [
        {
            question: 'Do I need Wi-Fi?',
            answer: 'text.'
        },
        {
            question: 'Do I need a certain cell phone provider?',
            answer: 'textexexexexexexexexexextextexexexexexexexexexextextexexexexexexexexexextextexexexexexexexexexextextexexexexexexexexexextextexexexexexexexexexextextexexexexexexexexexextextexexexexexexexexexextextexexexexexexexexexextextexexexexexexexexexextextexexexexexexexexexextextexexexexexexexexexextextexexexexexexexexexextextexexexexexexexexexextextexexexexexexexexexextextexexexexexexexexexextextexexexexexexexexexextextexexexexexexexexexextextexexexexexexexexexextextexexexexexexexexexextextexexexexexexexexexextextexexexexexexexexexextextexexexexexexexexexextextexexexexexexexexexextextexexexexexexexexexextextexexexexexexexexexextextexexexexexexexexexextextexexexexexexexexexextextexexexexexexexexexextextexexexexexexexexexextextexexexexexexexexexextextexexexexexexexexexextextexexexexexexexexexextextexexexexexexexexexextextexexexexexexexexexextextexexexexexexexexexextextexexexexexexexexexex.'
        },
        {
            question: 'What happens if the power goes out?',
            answer: 'text.'
        },
        {
            question: 'What equipment does Cove offer?',
            answer: 'text.'
        },
        {
            question: 'What equipment does Cove offer?',
            answer: 'text.'
        },
        {
            question: 'What equipment does Cove offer?',
            answer: 'text.'
        },
        {
            question: 'What equipment does Cove offer?',
            answer: 'text.'
        },
        {
            question: 'What equipment does Cove offer?',
            answer: 'text.'
        }
    ];

    return (
        <div className="faq-page">
            <Header titleName="Smart Home FAQ"/>
            <section className="faq-section">
                <h3 className="faq-title">Frequently Asked Questions</h3>
                <div className="faq-wrapper">
                    {faqs.map((faq, index) => (
                        <div key={index} className={`faq-item ${openIndex === index ? 'open' : ''}`}>
                            <div
                                className="faq-summary"
                                onClick={() => setOpenIndex(openIndex === index ? null : index)}
                            >
                                <div className="faq-question">{faq.question}</div>
                                <div className="faq-expand-icon">
                                    <button className="expand-btn">
                                        <Icon
                                            path={openIndex === index ? mdiMinus : mdiPlus} // MDI icons
                                            size={1}
                                            color="#007bff"
                                        />
                                    </button>
                                </div>
                            </div>
                            <div className={`faq-details ${openIndex === index ? 'show' : ''}`}>
                                {faq.answer}
                            </div>
                        </div>
                    ))}
                </div>
            </section>
            <BackHomeBtn/>
            <Footer/>
        </div>
    );
};

export default FAQPage;
